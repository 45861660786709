import { default as _91_46_46_46cms_934KyD2vEtVzMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93nJcdjRwVZuMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/3d-projects/[...slug].vue?macro=true";
import { default as indexp7mBM5CRy5Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/3d-projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93Wyl0HJrxXMMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/akcii/[...slug].vue?macro=true";
import { default as index443xM4h7KgMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/akcii/index.vue?macro=true";
import { default as _91_46_46_46slug_93xVdAe8k9dsMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/articles/[...slug].vue?macro=true";
import { default as indexw9c5WGNgp1Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/articles/index.vue?macro=true";
import { default as indextIPZdZiwmdMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/authorization/index.vue?macro=true";
import { default as index3ceXGAG1nYMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/cart/index.vue?macro=true";
import { default as indexzjZ624jEFFMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_931zZZf7Ri8JMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/catalog/[...slug].vue?macro=true";
import { default as indexuDagoFEb6JMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93fCFbPltd7ZMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/catalogsearch/[...slug].vue?macro=true";
import { default as resultgmnNnifuOgMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/catalogsearch/result.vue?macro=true";
import { default as indexQiHulYcHcQMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/clear-page-slider/index.vue?macro=true";
import { default as _91_46_46_46slug_93kYP9ooN4otMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/collection/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93SOZN3YuXkeMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/fabriki/[...slug].vue?macro=true";
import { default as indexrgRroKvSFhMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/fabriki/index.vue?macro=true";
import { default as _91_46_46_46slug_93pQ44UnDLqhMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/product/[...slug].vue?macro=true";
import { default as indexg6l0aCGmKrMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/profile/index.vue?macro=true";
import { default as indexR6e4yoQ5nBMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/profile/orders/index.vue?macro=true";
import { default as indexEAnqWlAf5qMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/recently/index.vue?macro=true";
import { default as _91_46_46_46slug_93jwJvPW6XrcMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/sets/[...slug].vue?macro=true";
import { default as index2GGCQS0SG2Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_934KyD2vEtVzMeta?.name ?? "cms",
    path: _91_46_46_46cms_934KyD2vEtVzMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_934KyD2vEtVzMeta || {},
    alias: _91_46_46_46cms_934KyD2vEtVzMeta?.alias || [],
    redirect: _91_46_46_46cms_934KyD2vEtVzMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93nJcdjRwVZuMeta?.name ?? "3d-projects-slug",
    path: _91_46_46_46slug_93nJcdjRwVZuMeta?.path ?? "/3d-projects/:slug(.*)*",
    meta: _91_46_46_46slug_93nJcdjRwVZuMeta || {},
    alias: _91_46_46_46slug_93nJcdjRwVZuMeta?.alias || [],
    redirect: _91_46_46_46slug_93nJcdjRwVZuMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/3d-projects/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexp7mBM5CRy5Meta?.name ?? "3d-projects",
    path: indexp7mBM5CRy5Meta?.path ?? "/3d-projects",
    meta: indexp7mBM5CRy5Meta || {},
    alias: indexp7mBM5CRy5Meta?.alias || [],
    redirect: indexp7mBM5CRy5Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/3d-projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Wyl0HJrxXMMeta?.name ?? "akcii-slug",
    path: _91_46_46_46slug_93Wyl0HJrxXMMeta?.path ?? "/akcii/:slug(.*)*",
    meta: _91_46_46_46slug_93Wyl0HJrxXMMeta || {},
    alias: _91_46_46_46slug_93Wyl0HJrxXMMeta?.alias || [],
    redirect: _91_46_46_46slug_93Wyl0HJrxXMMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/akcii/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index443xM4h7KgMeta?.name ?? "akcii",
    path: index443xM4h7KgMeta?.path ?? "/akcii",
    meta: index443xM4h7KgMeta || {},
    alias: index443xM4h7KgMeta?.alias || [],
    redirect: index443xM4h7KgMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xVdAe8k9dsMeta?.name ?? "articles-slug",
    path: _91_46_46_46slug_93xVdAe8k9dsMeta?.path ?? "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93xVdAe8k9dsMeta || {},
    alias: _91_46_46_46slug_93xVdAe8k9dsMeta?.alias || [],
    redirect: _91_46_46_46slug_93xVdAe8k9dsMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexw9c5WGNgp1Meta?.name ?? "articles",
    path: indexw9c5WGNgp1Meta?.path ?? "/articles",
    meta: indexw9c5WGNgp1Meta || {},
    alias: indexw9c5WGNgp1Meta?.alias || [],
    redirect: indexw9c5WGNgp1Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indextIPZdZiwmdMeta?.name ?? "authorization",
    path: indextIPZdZiwmdMeta?.path ?? "/authorization",
    meta: indextIPZdZiwmdMeta || {},
    alias: indextIPZdZiwmdMeta?.alias || [],
    redirect: indextIPZdZiwmdMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: index3ceXGAG1nYMeta?.name ?? "cart",
    path: index3ceXGAG1nYMeta?.path ?? "/cart",
    meta: index3ceXGAG1nYMeta || {},
    alias: index3ceXGAG1nYMeta?.alias || [],
    redirect: index3ceXGAG1nYMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexzjZ624jEFFMeta?.name ?? "cart-success",
    path: indexzjZ624jEFFMeta?.path ?? "/cart/success",
    meta: indexzjZ624jEFFMeta || {},
    alias: indexzjZ624jEFFMeta?.alias || [],
    redirect: indexzjZ624jEFFMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_931zZZf7Ri8JMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_931zZZf7Ri8JMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_931zZZf7Ri8JMeta || {},
    alias: _91_46_46_46slug_931zZZf7Ri8JMeta?.alias || [],
    redirect: _91_46_46_46slug_931zZZf7Ri8JMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexuDagoFEb6JMeta?.name ?? "catalog",
    path: indexuDagoFEb6JMeta?.path ?? "/catalog",
    meta: indexuDagoFEb6JMeta || {},
    alias: indexuDagoFEb6JMeta?.alias || [],
    redirect: indexuDagoFEb6JMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93fCFbPltd7ZMeta?.name ?? "catalogsearch-slug",
    path: _91_46_46_46slug_93fCFbPltd7ZMeta?.path ?? "/catalogsearch/:slug(.*)*",
    meta: _91_46_46_46slug_93fCFbPltd7ZMeta || {},
    alias: _91_46_46_46slug_93fCFbPltd7ZMeta?.alias || [],
    redirect: _91_46_46_46slug_93fCFbPltd7ZMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/catalogsearch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: resultgmnNnifuOgMeta?.name ?? "catalogsearch-result",
    path: resultgmnNnifuOgMeta?.path ?? "/catalogsearch/result",
    meta: resultgmnNnifuOgMeta || {},
    alias: resultgmnNnifuOgMeta?.alias || [],
    redirect: resultgmnNnifuOgMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/catalogsearch/result.vue").then(m => m.default || m)
  },
  {
    name: indexQiHulYcHcQMeta?.name ?? "clear-page-slider",
    path: indexQiHulYcHcQMeta?.path ?? "/clear-page-slider",
    meta: indexQiHulYcHcQMeta || {},
    alias: indexQiHulYcHcQMeta?.alias || [],
    redirect: indexQiHulYcHcQMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/clear-page-slider/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93kYP9ooN4otMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93kYP9ooN4otMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93kYP9ooN4otMeta || {},
    alias: _91_46_46_46slug_93kYP9ooN4otMeta?.alias || [],
    redirect: _91_46_46_46slug_93kYP9ooN4otMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93SOZN3YuXkeMeta?.name ?? "fabriki-slug",
    path: _91_46_46_46slug_93SOZN3YuXkeMeta?.path ?? "/fabriki/:slug(.*)*",
    meta: _91_46_46_46slug_93SOZN3YuXkeMeta || {},
    alias: _91_46_46_46slug_93SOZN3YuXkeMeta?.alias || [],
    redirect: _91_46_46_46slug_93SOZN3YuXkeMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/fabriki/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexrgRroKvSFhMeta?.name ?? "fabriki",
    path: indexrgRroKvSFhMeta?.path ?? "/fabriki",
    meta: indexrgRroKvSFhMeta || {},
    alias: indexrgRroKvSFhMeta?.alias || [],
    redirect: indexrgRroKvSFhMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/fabriki/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93pQ44UnDLqhMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93pQ44UnDLqhMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93pQ44UnDLqhMeta || {},
    alias: _91_46_46_46slug_93pQ44UnDLqhMeta?.alias || [],
    redirect: _91_46_46_46slug_93pQ44UnDLqhMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexg6l0aCGmKrMeta?.name ?? "profile",
    path: indexg6l0aCGmKrMeta?.path ?? "/profile",
    meta: indexg6l0aCGmKrMeta || {},
    alias: indexg6l0aCGmKrMeta?.alias || [],
    redirect: indexg6l0aCGmKrMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexR6e4yoQ5nBMeta?.name ?? "profile-orders",
    path: indexR6e4yoQ5nBMeta?.path ?? "/profile/orders",
    meta: indexR6e4yoQ5nBMeta || {},
    alias: indexR6e4yoQ5nBMeta?.alias || [],
    redirect: indexR6e4yoQ5nBMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/profile/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexEAnqWlAf5qMeta?.name ?? "recently",
    path: indexEAnqWlAf5qMeta?.path ?? "/recently",
    meta: indexEAnqWlAf5qMeta || {},
    alias: indexEAnqWlAf5qMeta?.alias || [],
    redirect: indexEAnqWlAf5qMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/recently/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jwJvPW6XrcMeta?.name ?? "sets-slug",
    path: _91_46_46_46slug_93jwJvPW6XrcMeta?.path ?? "/sets/:slug(.*)*",
    meta: _91_46_46_46slug_93jwJvPW6XrcMeta || {},
    alias: _91_46_46_46slug_93jwJvPW6XrcMeta?.alias || [],
    redirect: _91_46_46_46slug_93jwJvPW6XrcMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/sets/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index2GGCQS0SG2Meta?.name ?? "wishlist",
    path: index2GGCQS0SG2Meta?.path ?? "/wishlist",
    meta: index2GGCQS0SG2Meta || {},
    alias: index2GGCQS0SG2Meta?.alias || [],
    redirect: index2GGCQS0SG2Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250403080341/pages/wishlist/index.vue").then(m => m.default || m)
  }
]